import humps from 'humps'
import { defineAction } from '_/utils/redux'
import * as linksServices from '_services/messaging'

export const GET_MESSAGING_DETAILS = defineAction('GET_MESSAGING_DETAILS')
export const POST_MESSAGING = defineAction('POST_MESSAGING')
export const PATCH_MESSAGING = defineAction('PATCH_MESSAGING')
export const DELETE_MESSAGING = defineAction('DELETE_MESSAGING')
export const GET_SET_ITEMS = defineAction('GET_SET_ITEMS')
export const GET_MESSAGING = defineAction('GET_MESSAGING')
export const GET_MESSAGING_BY_SERVICE_ORDER_ID = defineAction('GET_MESSAGING_BY_SERVICE_ORDER_ID')
export const POST_SEND_WHATSAPP_LOG = defineAction('POST_SEND_WHATSAPP_LOG')

export const getMessaging = params => async (dispatch, getState) => {
  const formattedParams = humps.decamelizeKeys(params)
  const data = await linksServices.getMessage(getState().user.authToken)(formattedParams)
  dispatch({
    type: GET_MESSAGING.ACTION,
    payload: async () => data,
  })
  return data
}

export const getMessagingByServiceOrderId = params => async (dispatch, getState) => {
  const formattedParams = humps.decamelizeKeys(params)
  const data = await linksServices.getMessagingByServiceOrderId(getState().user.authToken)(
    formattedParams
  )
  dispatch({
    type: 'GET_MESSAGING_BY_SERVICE_ORDER_ID',
    payload: async () => data,
  })
  return data
}

export const postSendWhatsAppLog = payload => async (dispatch, getState) => {
  const data = await linksServices.postSendWhatsAppLog(getState().user.authToken, payload)

  dispatch({
    type: POST_SEND_WHATSAPP_LOG.ACTION,
    payload: async () => data,
  })

  return data
}

export const getMessagingDetails = messageId => async (dispatch, getState) => {
  const data = await linksServices.getMessagingDetails(getState().user.authToken, messageId)
  dispatch({
    type: 'GET_MESSAGING_DETAILS',
    payload: async () => data,
  })
  return data
}

export const postMessaging = payload => async (dispatch, getState) => {
  const data = await linksServices.postMessaging(getState().user.authToken, payload)
  dispatch({
    type: 'POST_MESSAGING',
    payload: async () => data,
  })
  return data
}

export const patchMessaging = (messageId, payload) => async (dispatch, getState) => {
  const data = await linksServices.patchMessaging(getState().user.authToken, messageId, payload)
  dispatch({
    type: 'POST_MESSAGING',
    payload: async () => data,
  })
  return data
}

export const deleteMessaging = messageId => async (dispatch, getState) => {
  const data = await linksServices.deleteMessaging(getState().user.authToken, messageId)
  dispatch({
    type: 'DELETE_MESSAGING',
    payload: async () => data,
  })
  return data
}

export const getSetItems = () => (dispatch, getState) =>
  dispatch({
    type: 'GET_SET_ITEMS',
    payload: linksServices.getSetItems(getState().user.authToken),
  })
